
/* footer */
.footer{
    display: flex;
    align-items: center;
    padding: 3vw 7vw;
    flex-wrap: wrap;
    color: white;
}

.footer .list{
    display: flex;
    align-items: center;
}
.footer .list > div:nth-child(1){
    padding: 2.1em;
}
.footer .list > div:nth-child(2){
    background-color: #c4c4c4;
    width: 0.1em;
    height:8em;
}

.footer > div{
    /* flex: 1 0 40%; */
    min-width: 33vh;
}

.footer > div.links{
    padding: 2.1em;
    /* flex: 1 0 21%; */
    min-width: 33vh;
    padding-top: 3vw;
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
}

.footer .sperator{
    background-color: #c4c4c4;
    width: 0.1em;
    height:8em;
    flex: unset;
    min-width: unset;
}
.footer .right{
    padding-left: 3.1em !important;
}

.footer > div.social{
    padding: 0 2.1em;
    flex: 1 0 21%;
    min-width: 33vh;
}

.footer a{
    display: block;
    margin-top: 1rem;
    color: white;
    text-decoration: none;
    font-family:'Metropolis-Bold';
    font-size:  min(max(1rem,calc(1rem + 0.01vw)),1rem);
    position: relative;
    z-index: 2;
}
.footer .social-icons ul{
    list-style-type: none;
    position: relative;
    z-index: 2;
    margin-top: 4vh;
}

.footer .social-icons ul li{
    display: inline-block;
    margin-right: 2rem;
}

.footer img{
    width: 75%;
    height: auto;
    position: relative;
    z-index: 4;
}


.footer p{
   font-family: 'Metropolis-Light';
   line-height: 1.2rem;
}

.footer .small{
    font-family: 'Metropolis-Light';
    font-size: min(max(0.9rem,calc(0.9rem + 0.01vw)),1rem);
}

.footer ul{
    list-style: none;
    padding: 0;
    margin-top: 0;
}

.footer ul li{
    font-family:'Metropolis-Light';
    margin-top: 1.1vw;
    font-size:  min(max(0.9rem,calc(0.9rem + 0.01vw)),1rem);;
    position: relative;
    z-index: 2;
}

.footer ul li a{ 
    font-family:'Metropolis-Light';
    position: relative;
    z-index: 4;
}


.copy-right{
    color: #9d9d9d;
    margin-top: 1rem;
}

.copy-right p{
    position: relative;
    z-index: 2;
    display: inline-block;
    margin: 0;
    font-family: 'Metropolis-Bold';
}

.footer .social .social-media {
    padding: 0;
    margin: 0;
 }
.footer .social  .social-media li{
     display: inline;
     color: rgb(255, 255, 255);
     font-size: 1em;
     cursor: pointer;
     margin-right: 1em;
 }

 .footer .social  a{
    display: inline;
}

/* 990px and below */
@media screen and (max-width: 1550px) {
    .footer > div.links{
        padding: 1.5em;
        flex: unset;
        min-width: 33vh;

    }
    .footer .list > div:nth-child(1){
        padding: unset;
    }
    .footer > div{
        flex: unset;
    }
}
@media screen and (max-width: 990px){
    .footer > div.links{
        justify-content: flex-start;
    }
    .sperator{
        display: none;
    }
}

@media screen and (max-width: 672px) {

    .footer .list > div:nth-child(2){
        display: none;
    }

    .footer a {
        margin-top: 1rem;
    }

    .footer > div {
        margin-bottom: 3vh;
    }

    .footer li {
        margin-top: 1vh;
    }

    .copy-right{
       font-size: 0.7rem;
    }
}

@media screen and (max-width: 300px) {

}
